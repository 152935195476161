import React, {Fragment, useEffect, useState, useContext} from 'react';
import InputField from '../../inputs/InputField';
import DatePicker from '../../inputs/DatePicker';
import RadioPicker from '../../inputs/RadioPicker';
import * as Yup from 'yup';
import moment from 'moment';
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {clear_email, update_golfer} from '../../features/golfer/golferSlice';
import * as EmailValidator from 'email-validator';
import GolferService from "../../services/api/golfer";
import {processErrorMessage} from "../../shared/errorHelper";
import ConfirmationModalTypeB from "../modals/ConfirmationModalTypeB";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { sendGAFormEvent } from "../../tracking/ga";
import { JoinPageContext } from '../../context/join_page_context';


function Golfer() {
  const {state} = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { club_id, url } = useParams();
  const { JoinPageData } = useContext(JoinPageContext);

  const golfer_data = useSelector(state => state.golfer.golfer) || {}
  const [golferDetails, setGolferDetails] = useState({});

  const [errors, setErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [existingError, setExistingError] = useState("");
  const [confirmResponse, setConfirmResponse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [forceAge, setForceAge] = useState(false);

  const is_minor = () => {
    const age = moment().diff(moment(golferDetails.date_of_birth).format('YYYY/MM/DD'), 'years');
    return age < 13
  }

  const firstNameValidationSchema = {'First Name': Yup.string().required()};
  const lastNameValidationSchema = {'Last Name': Yup.string().required()};
  const emailValidationSchema = {'Email Address': Yup.string().required().test('valid-email', 'Invalid Email Address', (value) => {
      return EmailValidator.validate(value);
    })};
  const phoneValidationSchema = {'Phone': Yup.string()}
  const dateValidationSchema = {'Date of Birth': Yup.string().required()}
  const genderValidationSchema = {'Gender': Yup.string().required()}
  let validators = {
    ...firstNameValidationSchema,
    ...lastNameValidationSchema,
    ...phoneValidationSchema,
    ...dateValidationSchema,
    ...genderValidationSchema
  }

  if (!is_minor())  {
    validators = {
      ...validators,
      ...emailValidationSchema
    };
  }

  const validationSchema = Yup.object({
    ...validators
  })

  const handleFormValues = (event) => {
    if (event.target.name === 'gender' && event.target.value === golferDetails.gender) {
      setGolferDetails({
        ...golferDetails,
        gender: ''
      });
    } else
      setGolferDetails({
        ...golferDetails, [event.target.name]: event.target.value,
        has_digital_profile: false,
        is_minor: is_minor() ? true : false
      })
  }

  useEffect(() => {
    const validateFields = () => {
      validationSchema
        .isValid({
          'First Name': golferDetails.first_name,
          'Last Name': golferDetails.last_name,
          'Email Address': golferDetails.email,
          'Phone': golferDetails.phone_number,
          'Date of Birth': golferDetails.date_of_birth,
          'Gender': golferDetails.gender
        })
        .then(res => {
          setErrors(!res);
        })
    }
    validateFields();
  }, [golferDetails, validationSchema]);

  useEffect(() => {
    const utc_dob = moment.utc(golfer_data.date_of_birth, 'YYYY-MM-DD');
    setGolferDetails({
      id: golfer_data.id,
      first_name: golfer_data.first_name,
      last_name: golfer_data.last_name,
      email: golfer_data.email,
      phone_number: golfer_data.phone_number,
      date_of_birth: golfer_data.date_of_birth ? new Date(utc_dob.year(), utc_dob.month(), utc_dob.date()) : '',
      gender: golfer_data.gender,
      primary_club_id: golfer_data.primary_club_id,
      has_active_guardians: golfer_data.has_active_guardians,
      has_digital_profile: golfer_data.has_digital_profile,
      is_minor: golfer_data.is_minor
    })
  }, [golfer_data]);

  const needsGuardian = () => {
    const age = moment().diff(moment(golferDetails.date_of_birth).format('YYYY/MM/DD'), 'years');
    return age < 13 && !golferDetails.has_active_guardians
  }

  const handleGolferUpdateAndNavigation = () => {
    dispatch(update_golfer({...golferDetails}))
    if (is_minor())
      dispatch(clear_email())
    if (state?.navigate_to && needsGuardian())
      navigate(`../guardian`);
    else
      navigate(`../address`);
  }
  
  const parsePhone = (phoneNumber) => {
    let number = phoneNumber;
    if (number && number.length === 10) {
      const aux = number.split('');
      number = `(${aux.slice(0, 3).join('')}) ${aux.slice(3, 6).join('')}-${aux.slice(6, 10).join('')}`
    }

    return number;
  }

  const checkAgeAndProceed = () => {
    if (!needsGuardian() && golferDetails.id)
      saveData();
    else {
      const age = moment().diff(moment(golferDetails.date_of_birth).format('YYYY/MM/DD'), 'years');

      if (age <= 5 || age >= 85) {
        setConfirmResponse(true);
        setExistingError(`Please verify the birthdate entered. Birthdate: ${moment(golferDetails.date_of_birth).format('MM/DD/YYYY')} Press the "Confirm" button to continue if the birthdate is correct. To edit the birthdate before submitting the form, press the "Back" button.`);
      }
      else
        saveData();
    }
  }

  const saveData = () => {
    if (!needsGuardian() && golferDetails.id) { //existing golfer case
      const age = moment().diff(moment(golferDetails.date_of_birth).format('YYYY/MM/DD'), 'years');
      const request_data = {
        golfer: {
          first_name: golferDetails.first_name,
          last_name: golferDetails.last_name,
          email: age < 13 ? '' : golferDetails.email,
          phone_number: golferDetails.phone_number,
          date_of_birth: golferDetails.date_of_birth ? moment(golferDetails.date_of_birth).format('DD-MMM-YYYY') : null,
          gender: golferDetails.gender
        },
        jr_join_club_id: club_id,
        jr_join_page_url: `/${url}/club/${club_id}`
      }

      GolferService.updateGolfer(golferDetails.primary_club_id, golferDetails.id, request_data)
        .then(res => {
          dispatch(update_golfer({
            first_name: res.golfers.first_name,
            last_name: res.golfers.last_name,
            email: res.golfers.email,
            phone_number: res.golfers.phone_number,
            date_of_birth: res.golfers.date_of_birth,
            gender: res.golfers.gender
          }));
          if (state?.navigate_to)
            navigate(state.navigate_to);
          else
            navigate(`../address`);

        })
        .catch(err => {
          if (err.data) {
            console.log("error")
            const errorMessage = processErrorMessage(err.data.errors);
            setErrors(errorMessage);
            setErrorMessage(errorMessage);
            if (err.data.show_confirmation) {
              setForceAge(!!err.data.age_confirmation);
              setConfirmResponse(true);
            }
          }
        })
    } else {
      if (!golferDetails.id && !is_minor()) { // new golfer case
        setLoading(true);
        let params = {
          page: 1,
          per_page: 100,
          global_search: true,
          search: golferDetails.email
        };
        GolferService.getGolfers(params)
          .then(res => {
            const statuses = res.golfers.map((g) => g.status)
            const allArchived = statuses.every(element => element === 'Archived');

            if (allArchived) {
              handleGolferUpdateAndNavigation()
            } else {
              let association_details = JoinPageData.golf_association_details
              setExistingError(`A golfer with the specified email address already exists. For help, please contact the ${association_details.name} at ${parsePhone(association_details.phone)}, or via email at ${association_details.email}.`);
              setLoading(false);
            }
          })
          .catch(err => {
            handleGolferUpdateAndNavigation()
            setLoading(false);
          })
      } else {
        handleGolferUpdateAndNavigation()
      }
    }
  }

  const onSubmit = () => {
    if (golferDetails.id) {
      const user_properties = {
        club_id: club_id,
        association_id: JoinPageData.golf_association_details.id.toString(),
        ghin_number: golferDetails.id
      }
      sendGAFormEvent(user_properties, "Edit Golfer Details Form")
    } else  {
      const user_properties = {
        club_id: club_id,
        association_id: JoinPageData.golf_association_details.id.toString(),
        ghin_number: "-"
      }
      sendGAFormEvent(user_properties, "Add Golfer Details Form")
    }

    checkAgeAndProceed()
  }

  const updateGolfer = () => {
    const age = moment().diff(moment(golferDetails.date_of_birth).format('YYYY/MM/DD'), 'years');
    const request_data = {
      golfer: {
        first_name: golferDetails.first_name,
        last_name: golferDetails.last_name,
        email: age < 13 ? '' : golferDetails.email,
        phone_number: golferDetails.phone_number,
        date_of_birth: golferDetails.date_of_birth ? moment(golferDetails.date_of_birth).format('DD-MMM-YYYY') : null,
        gender: golferDetails.gender
      },
      jr_join_club_id: club_id,
      jr_join_page_url: `/${url}/club/${club_id}`,
      force: true,
      force_age: forceAge
    }

    GolferService.updateGolfer(golferDetails.primary_club_id, golferDetails.id, request_data)
        .then(res => {
          dispatch(update_golfer({
            first_name: res.golfers.first_name,
            last_name: res.golfers.last_name,
            email: res.golfers.email,
            phone_number: res.golfers.phone_number,
            date_of_birth: res.golfers.date_of_birth,
            gender: res.golfers.gender
          }));
          if (state?.navigate_to)
            navigate(state.navigate_to);
          else
            navigate(`../address`);

        })
        .catch(err => {
          if (err.data) {
            console.log("error")
            const errorMessage = processErrorMessage(err.data.errors);
            setErrors(errorMessage);
            setErrorMessage(errorMessage);
            if (err.data.show_confirmation) {
              setForceAge(!!err.data.age_confirmation);
              setConfirmResponse(true);
            }
          }
        })
  }

  return (
    <Fragment>
      <div className='w-100 golfer_page'>
        <div className='box-panel center'>
          <div className='container'>

            <div className='center'>
              <span className='header-text'>
                <strong>Let's start with some basic info</strong>
              </span>
            </div>
            <div style={{marginTop: '20px'}} className='row with-no-bottom-margin w-100'>
              <div className='col is-1-of-2'>
                <InputField
                  className='mw-100'
                  size=''
                  id='first_name'
                  label='First Name'
                  value={golferDetails.first_name}
                  validationSchema={Yup.object(firstNameValidationSchema)}
                  onChange={handleFormValues}
                  autoComplete={'off'}
                  requiredIcon
                />
              </div>

              <div className='col is-1-of-2'>
                <InputField
                  className='mw-100'
                  size=''
                  id='last_name'
                  label='Last Name'
                  value={golferDetails.last_name}
                  validationSchema={Yup.object(lastNameValidationSchema)}
                  onChange={handleFormValues}
                  autoComplete={'off'}
                  requiredIcon
                />
              </div>
            </div>

            <div className='row with-no-bottom-margin w-100'>
              <div className='col is-1-of-2'>
                <InputField
                  className='mw-100'
                  size=''
                  id='email'
                  label='Email Address'
                  value={is_minor() ? undefined : golferDetails.email}
                  validationSchema={!is_minor() ? Yup.object(emailValidationSchema) : undefined}
                  onChange={handleFormValues}
                  autoComplete={'off'}
                  requiredIcon={!is_minor()}
                  disabled={is_minor()}
                />
              </div>

              <div className='col is-1-of-2'>
                <InputField
                  className='mw-100'
                  size=''
                  id='phone_number'
                  label='Phone'
                  value={golferDetails.phone_number}
                  validationSchema={Yup.object(phoneValidationSchema)}
                  onChange={handleFormValues}
                  autoComplete={'off'}
                />
              </div>
            </div>

            <div className='row w-100'>
              <div className='col is-1-of-2'>
                <DatePicker
                  className='mw-100'
                  size=''
                  id='date_of_birth'
                  label='Date of Birth'
                  value={golferDetails.date_of_birth}
                  onChange={handleFormValues}
                  validationSchema={Yup.object(dateValidationSchema)}
                  requiredIcon
                />
              </div>

              <div className='col is-1-of-2'>
                <RadioPicker
                  size=''
                  id='gender'
                  label={<>Gender <i className="light_red">*</i></>}
                  value={golferDetails.gender}
                  onChange={handleFormValues}
                />
              </div>
            </div>

            <div className="row">
              <div className="col is-1-of-2">
                {(state && state.previous_page === '') ||
                  <button
                    className="btn x-smaller fill gray top-margin"
                    type={"button"}
                    onClick={() => {
                      navigate(state && state.previous_page === 'golfer_details' ? `../${state.previous_page}` : '..');
                    }}
                  >
                    Go Back
                  </button>
                }
              </div>
              <div className="col is-1-of-2">
                <button
                  className="btn x-smaller fill cardinal top-margin"
                  type={"submit"}
                  disabled={errors || loading}
                  onClick={() => onSubmit()}
                >
                  {loading ? 'Loading...' : 'Continue'}
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>

      <ConfirmationModalTypeB
        wideClass={'responsive'}
        openModal={errorMessage.length > 0 && confirmResponse === false}
        onConfirmAction={() => { setErrorMessage('')}}
        onCancelAction={() => { setErrorMessage('')}}
        closeModal={() => { setErrorMessage('') }}
        question={errorMessage}
        confirmLabel={"Confirm"}
        modalIcon={CheckCircleOutlineIcon}
      />
    
      <ConfirmationModalTypeB
        wideClass={'responsive'}
        openModal={errorMessage.length > 0 && confirmResponse}
        onConfirmAction={() => { setErrorMessage(''); setConfirmResponse(false); updateGolfer() }}
        onCancelAction={() => { setErrorMessage(''); setConfirmResponse(false)}}
        closeModal={() => { setErrorMessage(''); setConfirmResponse(false)}}
        question={errorMessage}
        confirmLabel={"Confirm"}
        cancelLabel={"Cancel"}
        buttonClassNameCancel={"smaller center_horizontal_margin"}
        modalIcon={CheckCircleOutlineIcon}
      />

      <ConfirmationModalTypeB
        wideClass={'responsive'}
        openModal={existingError.length > 0 && confirmResponse}
        onConfirmAction={() => { setExistingError(''); setConfirmResponse(false); saveData(); }}
        onCancelAction={() => { setExistingError(''); setConfirmResponse(false)}}
        closeModal={() => { setExistingError(''); setConfirmResponse(false)}}
        question={existingError}
        confirmLabel={"Confirm"}
        cancelLabel={"Cancel"}
        buttonClassNameCancel={"smaller center_horizontal_margin"}
        modalIcon={CheckCircleOutlineIcon}
      />

      <ConfirmationModalTypeB
        wideClass={'responsive'}
        openModal={existingError.length > 0 && confirmResponse === false}
        onConfirmAction={() => { setExistingError('') }}
        onCancelAction={() => { setExistingError('') }}
        closeModal={() => { setExistingError('') }}
        message={[existingError]}
        hideTitle={true}
        confirmLabel={"Close"}
        buttonClassName={"existing-golfer-button"}
      />

    </Fragment>
  );
}

export default Golfer;
