import React, {Fragment, useEffect, useState, useContext} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import InputField from '../../inputs/InputField';
import Select from '../../inputs/Select'
import DatePicker from '../../inputs/DatePicker';
import {update_address, update_secondary_address, update_billing_address} from '../../features/golfer/golferSlice';
import * as Yup from "yup";
import GolferService from "../../services/api/golfer";
import moment from "moment";
import {processErrorMessage} from "../../shared/errorHelper";
import ConfirmationModalTypeB from "../modals/ConfirmationModalTypeB";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { sendGAFormEvent } from "../../tracking/ga";
import { JoinPageContext } from '../../context/join_page_context';
import { Checkbox } from "@mui/material";

function Address(props) {
  const {state} = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { club_id, url } = useParams();

  const [errors, setErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isBackendDown, setIsBackendDown] = useState(false);
  const golfer_data = useSelector(state => state.golfer.golfer)
  const current_address_data = useSelector(state => props.billing ? state.golfer.billing_address : state.golfer.address);
  const current_secondary_address_data = useSelector(state => state.golfer.secondary_address);
  const store_billing_address_data = useSelector(state => state.golfer.billing_address)
  const [addressDetails, setAddressDetails] = useState({});
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [secondaryAddressDetails, setSecondaryAddressDetails] = useState({hasSecondaryAddress: false});
  const [secondaryStates, setSecondaryStates] = useState([]);
  const club = useSelector(state => state.club.club_data)
  const { JoinPageData } = useContext(JoinPageContext);
  const address1ValidationSchema = { 'Address 1': Yup.string().required().max(30, "Address 1 must not be longer than 30 characters.") };
  const address2ValidationSchema = { 'Address 2': Yup.string().max(30, "Address 2 must not be longer than 30 characters.") }
  const cityValidationSchema = {'City': Yup.string().required()};
  const zipValidationSchema = {'Zip Code': Yup.string().required().matches(/^[0-9]+$/, "Must be only digits").min(5, 'Zip Code must have exactly 5 digits').max(5, 'Zip Code must have exactly 5 digits')};
  const stateValidationSchema = { 'State': Yup.string().not(["Select..."]).required() };
  const firstNameValidationSchema = {'First Name': props.billing === true ? Yup.string().required() : Yup.string()};
  const lastNameValidationSchema = {'Last Name': props.billing === true ? Yup.string().required() : Yup.string()};
  const secondaryAddress1ValidationSchema = { 'Secondary Address 1': Yup.string().required().max(30, "Address 1 must not be longer than 30 characters.") };
  const secondaryAddress2ValidationSchema = { 'Secondary Address 2': Yup.string().max(30, "Address 2 must not be longer than 30 characters.") }
  const secondaryCityValidationSchema = {'Secondary City': Yup.string().required()};
  const secondaryZipValidationSchema = {'Secondary Zip Code': Yup.string().required().matches(/^[0-9]+$/, "Must be only digits").min(5, 'Zip Code must have exactly 5 digits').max(5, 'Zip Code must have exactly 5 digits')};
  const secondaryStateValidationSchema = { 'Secondary State': Yup.string().not(["Select..."]).required() };
  const startDateValidationSchema = {'Start Date': Yup.string().required()}
  const endDateValidationSchema = {'End Date': Yup.string().required()}



  const validationSchema = Yup.object({
    ...address1ValidationSchema,
    ...address2ValidationSchema,
    ...cityValidationSchema,
    ...zipValidationSchema,
    ...stateValidationSchema,
    ...firstNameValidationSchema,
    ...lastNameValidationSchema
  });
  const secondayValidationSchema = Yup.object({
    ...validationSchema.fields,
    ...secondaryAddress1ValidationSchema,
    ...secondaryAddress2ValidationSchema,
    ...secondaryCityValidationSchema,
    ...secondaryZipValidationSchema,
    ...secondaryStateValidationSchema,
    ...startDateValidationSchema,
    ...endDateValidationSchema,
  });

  const handleFormValues = (event) => {
    if(event.target.name?.includes('secondary'))
      setSecondaryAddressDetails({...secondaryAddressDetails, [event.target.name.replace("secondary_", '')]: event.target.value});
    else
      setAddressDetails({...addressDetails, [event.target.name]: event.target.value});
  }

  useEffect(() => {
    GolferService.getCountriesAndStates()
      .then(res => {
        const updatedCountries = res.countries.map((item) => {
          let currentCountry = null;
          if (current_address_data.country) {
            if (current_address_data.country === item.name || current_address_data.country === item.code) {
              var temp_states = item.states
              if (temp_states?.length > 1) {
                temp_states = [{ name: '', code: 'Select...', course_code: '' }, ...temp_states]
              }

              setStates(temp_states);
              currentCountry = item.name;
            }
          } else if (item.name === 'United States') {
            setStates([{
              name: '',
              code: 'Select...',
              course_code: ''
            }, ...item.states]);
          }
          let secondaryCurrentCountry = null;
          if (current_secondary_address_data.country) {
            if (current_secondary_address_data.country === item.name || current_secondary_address_data.country === item.code) {
              temp_states = item.states
              if (temp_states?.length > 1) {
                temp_states = [{ name: '', code: 'Select...', course_code: '' }, ...temp_states]
              }

              setSecondaryStates(temp_states);
              secondaryCurrentCountry = item.name;
            }
          } else if (item.name === 'United States') {
            setSecondaryStates([{
              name: '',
              code: 'Select...',
              course_code: ''
            }, ...item.states]);
          }


          return {
            ...item,
            value: item.code,
            label: item.name,
            states: item.states.map((state) => {
              return {
                ...state,
                value: state.code,
                label: state.name
              };
            }),
            currentCountry,
            secondaryCurrentCountry,
          };
        });

        const existingCountry = updatedCountries.find((country) => country.currentCountry);
        const secondaryExistingCountry = updatedCountries.find((country) => country.secondaryCurrentCountry);
        setAddressDetails({
          street_1: '',
          street_2: '',
          city: '',
          zip: '',
          ...current_address_data,
          country: existingCountry ? existingCountry.currentCountry : 'United States',
          state: current_address_data.state ? current_address_data.state : 'Select...'
        });
        setSecondaryAddressDetails({
          street_1: '',
          street_2: '',
          city: '',
          zip: '',
          ...current_secondary_address_data,
          hasSecondaryAddress: current_secondary_address_data.hasSecondaryAddress || !!state?.show_secondary_address,
          country: secondaryExistingCountry ? secondaryExistingCountry.secondaryCurrentCountry : 'United States',
          state: current_secondary_address_data.state ? current_secondary_address_data.state : 'Select...',
          active_from: current_secondary_address_data.active_from ? moment(current_secondary_address_data.active_from, 'YYYY-MM-DD').toDate() : '',
          active_to: current_secondary_address_data.active_to ? moment(current_secondary_address_data.active_to, 'YYYY-MM-DD').toDate() : '',
        });
        setCountries(updatedCountries);
      })
      .catch(err => {
        setIsBackendDown(true);
        setErrorMessage(processErrorMessage(err.data.error))
        console.log(err);
      })
  }, [state, golfer_data, current_address_data, current_secondary_address_data, club.id]);

  useEffect(() => {
    validateFields(validationSchema, addressDetails, secondaryAddressDetails);
    if(secondaryAddressDetails.hasSecondaryAddress)
      validateFields(secondayValidationSchema, addressDetails, secondaryAddressDetails);
  }, [addressDetails, secondaryAddressDetails, validationSchema, secondayValidationSchema]);

  const onCountryChange = (event) => {
    const country = event.target.value
    var current_states = countries.find(el => el.name === country).states;
    current_states = current_states?.length === 1 ? current_states : [{
      name: '',
      code: 'Select...',
      value: 'Select...',
      course_code: ''
    },
    ...current_states
    ]

    if(event.target.name?.includes('secondary')){
      setSecondaryStates(current_states);
      setSecondaryAddressDetails({...secondaryAddressDetails,
        country: country,
        state: current_states[0]["value"]});
    } else {
      setStates(current_states);
      setAddressDetails({...addressDetails,
        country: country,
        state: current_states[0]["value"]});
    }
  }

  const validateFields = (validationSchema, addressDetails, secondaryAddressDetails) => {
    validationSchema
      .isValid({
        'Address 1': addressDetails.street_1,
        'Address 2': addressDetails.street_2 || '',
        'City': addressDetails.city,
        'Zip Code': addressDetails.zip,
        'State': addressDetails.state,
        'First Name': addressDetails.first_name,
        'Last Name': addressDetails.last_name,
        'Secondary Address 1': secondaryAddressDetails.street_1,
        'Secondary Address 2': secondaryAddressDetails.street_2 || '',
        'Secondary City': secondaryAddressDetails.city,
        'Secondary Zip Code': secondaryAddressDetails.zip,
        'Secondary State': secondaryAddressDetails.state,
        'Start Date': secondaryAddressDetails.active_from,
        'End Date': secondaryAddressDetails.active_to
      })
      .then(res => {
        setErrors(!res);
      })
  }

  const secondary_dates = () => {
    return {
      active_from: secondaryAddressDetails.active_from ? moment(secondaryAddressDetails.active_from).format("YYYY-MM-DD") : "", 
      active_to: secondaryAddressDetails.active_to ? moment(secondaryAddressDetails.active_to).format("YYYY-MM-DD") : ""
    }
  }

  const needsGuardian = () => {
    const age = moment().diff(moment(golfer_data.date_of_birth).format('YYYY/MM/DD'), 'years');
    return age < 13 && !golfer_data.has_active_guardians
  }

  const setHasSecondaryAddress = (hasSecondaryAddress) => {
    setSecondaryAddressDetails({...secondaryAddressDetails, hasSecondaryAddress: hasSecondaryAddress})
  }

  const onSubmit = () => {
    if (golfer_data.id) { //existing golfer case
      const user_properties = {
        club_id: club.id.toString(),
        association_id: JoinPageData.golf_association_details.id.toString(),
        ghin_number: golfer_data.id
      }
      sendGAFormEvent(user_properties, "Edit Golfer Address Form")
    } else {
      const user_properties = {
        club_id: club.id.toString(),
        association_id: JoinPageData.golf_association_details.id.toString(),
        ghin_number: "-"
      }
      sendGAFormEvent(user_properties, "Add Golfer Address Form")
    }
    
    if (needsGuardian() && props.billing === false) {
      dispatch(update_address({...addressDetails}));
      dispatch(update_secondary_address({...secondaryAddressDetails, ...secondary_dates()}));
      if (!store_billing_address_data || Object.keys(store_billing_address_data).length === 0)
        dispatch(update_billing_address({
          ...addressDetails,
          first_name: golfer_data.first_name,
          last_name: golfer_data.last_name
        }));
      navigate(`../guardian`);
    }
    else {
      if (golfer_data.id && !props.billing) {
        const request_data = {
          primary_address: {
            street_1: addressDetails.street_1,
            street_2: addressDetails.street_2,
            country: addressDetails.country,
            city: addressDetails.city,
            state: addressDetails.state,
            zip: addressDetails.zip
          },
          jr_join_page_url: `/${url}/club/${club_id}`
        }
        if(secondaryAddressDetails.hasSecondaryAddress)
          request_data['secondary_address'] = {
            street_1: secondaryAddressDetails.street_1,
            street_2: secondaryAddressDetails.street_2,
            country: secondaryAddressDetails.country,
            city: secondaryAddressDetails.city,
            state: secondaryAddressDetails.state,
            zip: secondaryAddressDetails.zip,
            active_from: moment(secondaryAddressDetails.active_from).format("YYYY-MM-DD"),
            active_to: moment(secondaryAddressDetails.active_to).format("YYYY-MM-DD"),
          }

        GolferService.updateGolfer(golfer_data.primary_club_id, golfer_data.id, request_data)
          .then(res => {
            dispatch(update_address({
              street_1: res.golfers.primary_address.street_1,
              street_2: res.golfers.primary_address.street_2,
              city: res.golfers.primary_address.city,
              state: res.golfers.primary_address.state,
              zip: res.golfers.primary_address.zip,
              country: res.golfers.primary_address.country || 'United States'
            }));
            dispatch(
              update_secondary_address({
                hasSecondaryAddress: !!res.golfers.secondary_address.zip,
                street_1: res.golfers.secondary_address.street_1,
                street_2: res.golfers.secondary_address.street_2,
                city: res.golfers.secondary_address.city,
                state: res.golfers.secondary_address.state,
                zip: res.golfers.secondary_address.zip,
                country: res.golfers.secondary_address.country || "United States",
                active_from: res.golfers.secondary_address.active_from ? moment(
                  res.golfers.secondary_address.active_from, "MM/DD"
                ).format("YYYY-MM-DD") : "",
                active_to: res.golfers.secondary_address.active_from ? moment(
                  res.golfers.secondary_address.active_from, "MM/DD"
                ).format("YYYY-MM-DD") : "",
              })
            );

            if (!store_billing_address_data || Object.keys(store_billing_address_data).length === 0)
              dispatch(update_billing_address({
                street_1: res.golfers.primary_address.street_1,
                street_2: res.golfers.primary_address.street_2,
                city: res.golfers.primary_address.city,
                state: res.golfers.primary_address.state,
                zip: res.golfers.primary_address.zip,
                country: res.golfers.primary_address.country || 'United States',
                first_name: golfer_data.first_name,
                last_name: golfer_data.last_name,
              }));
            navigate(`../golfer_details`, {state: {previous_page: 'address'}})
          })
          .catch(err => {
            if (err.data) {
              console.log(err)
              const errorMessage = processErrorMessage(err.data.errors);
              setErrors(errorMessage);
              setErrorMessage(processErrorMessage(errorMessage))
            }
          })
      } else {
        if (props.billing === true){
          dispatch(update_billing_address({...addressDetails}));
          navigate(`../payment`, {state: {previous_page: 'billing_address'}})
        }
        else{
          dispatch(update_address({...addressDetails}));
          dispatch(update_secondary_address({...secondaryAddressDetails, ...secondary_dates()}));
          if (!store_billing_address_data || Object.keys(store_billing_address_data).length === 0)
            dispatch(update_billing_address({
              ...addressDetails,
              first_name: golfer_data.first_name,
              last_name: golfer_data.last_name,
            }));
          navigate(`../membership`, {state: {previous_page: 'address'}})
        }
      }
    }

  }

  const closeErrorModal = () => {
    setErrorMessage('')
    if(isBackendDown)
      navigate(`../`);
  }

  const marginTop = props.billing ? '0px' : '20px';

  return (
    <Fragment>
      <div className='address_page'>
        <div className='box-panel center'>
          <div className='container mw-100 container-set-width'>

            <div className='center' style={{marginBottom: '20px'}}>
              <span className='header-text'>
                <strong>Please enter your Primary Address</strong>
              </span>
            </div>
            {props.billing && <div className='row w-100 no_margin'>
              <div className='col is-1-of-2 p-0'>
                <InputField
                    className='mw-100'
                    size=''
                    id='first_name'
                    label='First Name'
                    value={addressDetails.first_name}
                    validationSchema={Yup.object(firstNameValidationSchema)}
                    onChange={handleFormValues}
                    autoComplete={'off'}
                    requiredIcon
                />
              </div>

              <div className='col is-1-of-2 p-0'>
                <InputField
                    style={{marginLeft: '10px'}}
                    className='mw-100'
                    size=''
                    id='last_name'
                    label='Last Name'
                    value={addressDetails.last_name}
                    onChange={handleFormValues}
                    validationSchema={Yup.object(lastNameValidationSchema)}
                    autoComplete={'off'}
                    requiredIcon
                />
              </div>
            </div>}
            <div className="row w-100 no_margin">
              <Select
                style={{marginTop: marginTop, maxWidth: '100%', width: '100%'}}
                size='big'
                id='country'
                label={<>
                  Country <i className="light_red">*</i>
                </>}
                value={addressDetails.country}
                selectType={'label'}
                onChange={onCountryChange}
                options={countries}
                className={'w-100'}
                showError
              />
            </div>
            <div className="row w-100 no_margin">
              <InputField
                style={{width: '100%'}}
                className={"w-100"}
                size='big'
                id='street_1'
                label='Address 1'
                value={addressDetails.street_1}
                validationSchema={Yup.object(address1ValidationSchema)}
                onChange={handleFormValues}
                autoComplete={'off'}
                requiredIcon
              />
            </div>

            <div className="row w-100 no_margin">
              <InputField
                style={{width: '100%'}}
                className={"w-100"}
                size='big'
                id='street_2'
                label='Address 2'
                value={addressDetails.street_2}
                validationSchema={Yup.object(address2ValidationSchema)}
                onChange={handleFormValues}
                autoComplete={'off'}
              />
            </div>

            <div className='row w-100'>
              <div className='col is-1-of-3 p-0 city-col'>
                <InputField
                  className={"w-100"}
                  size='medium'
                  id='city'
                  value={addressDetails.city}
                  validationSchema={Yup.object(cityValidationSchema)}
                  label='City'
                  onChange={handleFormValues}
                  autoComplete={'off'}
                  requiredIcon
                />
              </div>
              <div className='col is-2-of-3 state-zip p-0'>
                <Select
                  style={{marginRight: '10px', width: "50%"}}
                  className={'w-100'}
                  size='small'
                  id='state'
                  label={<>
                    State <i className="light_red">*</i>
                  </>}
                  value={addressDetails.state}
                  selectType={'value'}
                  options={states}
                  onChange={handleFormValues}
                />
                <InputField
                  style={{width: '50%'}}
                  className={"w-100"}
                  size='small'
                  id='zip'
                  value={addressDetails.zip}
                  label='Zip Code'
                  validationSchema={Yup.object(zipValidationSchema)}
                  onChange={handleFormValues}
                  autoComplete={'off'}
                  requiredIcon
                />
              </div>
            </div>

            { props.billing === false &&
              <>
                <div className='secondary-checkbox'>
                  <div>
                    <Checkbox
                      checked={secondaryAddressDetails.hasSecondaryAddress}
                      onChange={() => setHasSecondaryAddress(!secondaryAddressDetails.hasSecondaryAddress)}
                    />
                  </div>
                  <div className="header-text">
                    <strong className='w-100'>Add Secondary Address</strong>
                  </div>
                </div>

                { secondaryAddressDetails.hasSecondaryAddress &&
                  <>
                    <div className='center'>
                      <span className='header-text'>
                        <strong>Please enter your Secondary Address</strong>
                      </span>
                    </div>
                    <div className="row w-100 no_margin">
                      <Select
                        style={{marginTop: '20px', maxWidth: '100%', width: '100%'}}
                        size='big'
                        id='secondary_country'
                        label={<>
                          Secondary Address Country <i className="light_red">*</i>
                        </>}
                        value={secondaryAddressDetails.country}
                        selectType={'label'}
                        onChange={onCountryChange}
                        options={countries}
                        className={'w-100'}
                        showError
                      />
                    </div>
                    <div className="row w-100 no_margin">
                      <InputField
                        style={{width: '100%'}}
                        className={"w-100"}
                        size='big'
                        id='secondary_street_1'
                        label='Secondary Address 1'
                        value={secondaryAddressDetails.street_1}
                        validationSchema={Yup.object(secondaryAddress1ValidationSchema)}
                        onChange={handleFormValues}
                        autoComplete={'off'}
                        requiredIcon
                      />
                    </div>
                    <div className="row w-100 no_margin">
                      <InputField
                        style={{width: '100%'}}
                        className={"w-100"}
                        size='big'
                        id='secondary_street_2'
                        label='Secondary Address 2'
                        value={secondaryAddressDetails.street_2}
                        validationSchema={Yup.object(secondaryAddress2ValidationSchema)}
                        onChange={handleFormValues}
                        autoComplete={'off'}
                      />
                    </div>
                    <div className='row w-100 no_margin'>
                      <div className='col is-1-of-3 p-0 city-col'>
                        <InputField
                          className={"w-100"}
                          size='medium'
                          id='secondary_city'
                          value={secondaryAddressDetails.city}
                          validationSchema={Yup.object(secondaryCityValidationSchema)}
                          label='Secondary City'
                          onChange={handleFormValues}
                          autoComplete={'off'}
                          requiredIcon
                        />
                      </div>
                      <div className='col is-2-of-3 state-zip p-0'>
                        <Select
                          style={{marginRight: '10px', width: "50%"}}
                          className={'w-100'}
                          size='small'
                          id='secondary_state'
                          label={<>
                            Secondary State <i className="light_red">*</i>
                          </>}
                          value={secondaryAddressDetails.state}
                          selectType={'value'}
                          options={secondaryStates}
                          onChange={handleFormValues}
                        />
                        <InputField
                          style={{width: '50%'}}
                          className={"w-100"}
                          size='small'
                          id='secondary_zip'
                          value={secondaryAddressDetails.zip}
                          label='Secondary Zip Code'
                          validationSchema={Yup.object(secondaryZipValidationSchema)}
                          onChange={handleFormValues}
                          autoComplete={'off'}
                          requiredIcon
                        />
                      </div>
                    </div>
                    <div className='row w-100 address-dates'>
                      <div className='address-date start-date'>
                        <DatePicker     
                          className='w-100'
                          size=''
                          id='secondary_active_from'
                          label='Start Date'
                          value={secondaryAddressDetails.active_from}
                          onChange={handleFormValues}
                          validationSchema={Yup.object(startDateValidationSchema)}
                          requiredIcon
                          hideYear
                        />                        
                      </div>
                      <div className='address-date end-date'>
                        <DatePicker
                          className='w-100'
                          size=''
                          id='secondary_active_to'
                          label='End Date'
                          value={secondaryAddressDetails.active_to}
                          onChange={handleFormValues}
                          validationSchema={Yup.object(endDateValidationSchema)}
                          requiredIcon
                          hideYear
                        />
                      </div>
                    </div>
                  </>
                }
              </>
            }
            <div className="row no_margin">
            {props.billing === false && <div className="col is-1-of-2">
                <button
                  onClick={() => {
                    dispatch(update_address({ ...addressDetails, modified: true }));
                    dispatch(update_secondary_address({ ...secondaryAddressDetails, ...secondary_dates() }));
                    
                    const navigate_to = state && state.previous_page
                    const previous_page = golfer_data.id ? 'golfer_details' : "..";
                    navigate( navigate_to ? navigate_to : `../golfer`, {
                      state: {
                        previous_page: previous_page
                      }
                    })
                  }}
                  className="btn smaller fill gray top-margin"
                  type={"button"}
                >
                  Go Back
                </button>
              </div>}
              <div className="col is-1-of-2">
                <button
                  className="btn smaller fill cardinal top-margin"
                  type={"submit"}
                  disabled={errors}
                  onClick={() => {
                    onSubmit()
                  }}
                >
                  {
                    needsGuardian() && props.billing === false ?
                      'Continue to Guardian Details'
                      :
                      'Continue'
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmationModalTypeB
        wideClass={'responsive'}
        openModal={errorMessage.length > 0}
        onConfirmAction={closeErrorModal}
        onCancelAction={closeErrorModal}
        closeModal={closeErrorModal}
        question={errorMessage}
        confirmLabel={"Confirm"}
        modalIcon={CheckCircleOutlineIcon}
      />

    </Fragment>
  );
}

export default Address;
