import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, {useEffect, useState} from "react";

export default function DatePickerComponent(props) {

  const [value, setValue] = useState(props.value || null);
  const [error, setError] = useState('');

  const MyContainer = ({ className, children }) => {
    return (
      <div>
        <CalendarContainer className={`${className} date_input_container`}>
          <div>{children}</div>
        </CalendarContainer>
      </div>
    );
  };

  const onBlurField = (event) => {
    setError('')
    if (props.validationSchema) {
      props.validationSchema
        .validate({
          [props.label]: event.target.value
        })
        .catch(err => {
          setError(err.errors.join('\n'));
        });
    }
  }

  const handleChangeValue = (event) => {
    setValue(event);
    if (props.validationSchema) {
      props.validationSchema
        .validate({
          [props.label]: event
        })
        .then(valid => {
          if (valid) {
            setError('');
            props.onChange({
              target: {
                value: event,
                name: props.id
              }
            });
          } else {
            setError('Date Of Birth is a required field');
          }
        })
        .catch(err => {
          setError(err.errors.join('\n'));
        });
    }
    else {
      props.onChange(event);
    }
  }

  useEffect(()=>{
    setValue(props.value)
  },[props.value, setValue])

  return (
    <div className={`create_input ${props.size}`}>
      <div className='row'>
        <div className='col is-full'>
          <label className='black' htmlFor={props.id}>
            {props.label} {props.requiredIcon && <i className="light_red">*</i>}
          </label>
          <DatePicker
            className={props.className}
            maxDate={props.hideYear ? null : new Date()}
            dropdownMode="select"
            dateFormat={props.hideYear ? 'MM/dd' : 'MM/dd/yyyy'}
            dateFormatCalendar={props.hideYear ? 'MM/dd' : 'MM/dd/yyyy'}
            onChange={(event) => handleChangeValue(event)}
            selected={value}
            name={props.id}
            showMonthDropdown={true}
            showYearDropdown={!props.hideYear}
            calendarContainer={MyContainer}
            autoComplete={'disabled'}
            onBlur={(event) => onBlurField(event)}
          />
          <span className={'error ' + props.size}>{error || ''}</span>
        </div>
      </div>
    </div>
  );
}
