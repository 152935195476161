import { createSlice } from "@reduxjs/toolkit";

export const golferSlice = createSlice({
  name: 'golfer_slice',
  initialState: {
    golfer: {},
    address: {},
    billing_address: {},
    secondary_address: {},
    guardian: {}
  },
  reducers: {
    update_golfer: (state, action) => {
      state.golfer = { ...state.golfer, ...action.payload }
    },
    update_address: (state, action) => {
      state.address = { ...state.address, ...action.payload }
    },
    update_secondary_address: (state, action) => {
      state.secondary_address = { ...state.secondary_address, ...action.payload }
    },
    update_billing_address: (state, action) => {
      state.billing_address = { ...state.billing_address, ...action.payload }
    },
    update_guardian: (state, action) => {
      state.guardian = { ...state.guardian, ...action.payload }
    },
    clear_golfer: (state) => {
      state.golfer = {}
    },
    clear_address: (state) => {
      state.address = {}
    },
    clear_secondary_address: (state) => {
      state.secondary_address = {}
    },
    clear_billing_address: (state) => {
      state.billing_address = {}
    },
    clear_guardian: (state) => {
      state.guardian = {}
    },
    clear_email: (state) => {
      state.golfer.email = null;
    }
  }
})

export const { update_golfer, update_address, update_secondary_address, update_billing_address, update_guardian, clear_golfer, clear_address, clear_secondary_address, clear_billing_address, clear_guardian, clear_email } = golferSlice.actions

export default golferSlice.reducer