import React, {useEffect} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import GolferService from "../../services/api/golfer";
import {setTokenCookie} from "../../auth";
import {useDispatch} from "react-redux";
import {update_memberships, update_selected_membership} from "../../features/membership/membershipSlice";
import {
  update_address, update_billing_address, update_golfer, update_guardian, update_secondary_address
} from "../../features/golfer/golferSlice";
import {update_logged_in_user} from "../../features/logged_in_user/loggedInUserSlice";
import moment from "moment/moment";

export default function AutoRenewFailed(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("jr_golfer_token");
  const update_card_details = queryParams.get("update_card_details");
  const golfer_email = queryParams.get("email");
  const club_id = params.club_id;
  const golfer_id = params.ghinNumber;

  const setTokens = (token) => {
    setTokenCookie(token, 'golfer');

    if (update_card_details === 'true') {
      sessionStorage.setItem('update_card_details', JSON.stringify(true));
    }
    else {
      sessionStorage.setItem('auto_renew_failed', JSON.stringify(true));
    }
  };
  
  function setTokensAfter(miliseconds) {
    return new Promise((resolve) => {
      setTimeout(() => {
        setTokens(token);
        resolve('done');
      }, miliseconds);
    });
  }

  const extractAddress = (address) => {
    return {
      street_1: address.street_1,
      street_2: address.street_2,
      city: address.city,
      state: address.state,
      zip: address.zip,
      country: address.country || 'United States'
    };
  };

  const dispatchGolferInfo = (golfer) => {
    dispatch(update_golfer({
      id: golfer.id,
      first_name: golfer.first_name,
      last_name: golfer.last_name,
      email: golfer.email,
      phone_number: golfer.phone_number,
      date_of_birth: golfer.date_of_birth,
      gender: golfer.gender,
      primary_club_id: golfer.primary_club_id,
      has_active_guardians: golfer.has_active_guardians,
      has_digital_profile: golfer.has_digital_profile,
      is_minor: golfer.is_minor
    }));
  };

  const dispatchAddressInfo = (primary_address, secondary_address, golfer) => {
    dispatch(update_address(primary_address));
    dispatch(update_billing_address({...primary_address, first_name: golfer.first_name, last_name: golfer.first_name}));
    dispatch(
      update_secondary_address({
        hasSecondaryAddress: !!secondary_address.zip,
        street_1: secondary_address.street_1,
        street_2: secondary_address.street_2,
        city: secondary_address.city,
        state: secondary_address.state,
        zip: secondary_address.zip,
        country: secondary_address.country || "United States",
        active_from: secondary_address.active_from ? moment(
          secondary_address.active_from, "MM/DD"
        ).format("YYYY-MM-DD") : "",
        active_to: secondary_address.active_to ? moment(
          secondary_address.active_to, "MM/DD"
        ).format("YYYY-MM-DD") : "",
      })
    );
  };

  const dispatchMembershipInfo = (membership) => {
    dispatch(update_memberships([membership]));
    dispatch(update_selected_membership({id: membership.id}));
  };

  const dispatchGuardianAndLoggedInInfo = (golfer, guardian) => {
    if (golfer.is_minor && guardian) {
      dispatch(update_guardian({
        id: guardian.golfer_id,
        first_name: guardian.first_name,
        middle_name: guardian.middle_name,
        last_name: guardian.last_name,
        phone_number: guardian.phone_number,
        email: guardian.email,
        relationship: guardian.relationship,
        other_relationship: ''
      }));
      dispatch(update_logged_in_user({
        email: guardian.email, is_guardian: true
      }));
    } else dispatch(update_logged_in_user({
      email: golfer.email, is_guardian: false
    }));
  };

  useEffect(() => {
    if (token) {
      const validate_membership = update_card_details !== 'true';
      setTokensAfter(1500).then(() => {
        GolferService.showGolfer(club_id, golfer_id, true, validate_membership, true).then(async res => {
          const golfer = res.golfers;
          if (golfer.status === "Active") {
            const primary_address = extractAddress(golfer.primary_address);
            const secondary_address = extractAddress(golfer.secondary_address);

            dispatchGolferInfo(golfer);
            dispatchAddressInfo(primary_address, secondary_address, golfer);
            dispatchMembershipInfo(golfer.club_membership_fee);
            dispatchGuardianAndLoggedInInfo(golfer, golfer.guardian);

            navigate(`../payment`);
          } else
            navigate(`../verify_golfer`, {state: {ghinNumber: golfer_id, emailAddress: golfer_email }});
        }).catch(e => {
          sessionStorage.removeItem('auto_renew_failed');
          sessionStorage.removeItem('update_card_details');

          if (e.data && e.data.error && e.data.error !== 'Invalid token')
            navigate(`../verify_golfer`, {state: {ghinNumber: golfer_id, emailAddress: golfer_email }});
        });
    });
    }
  }, [token]);

  return (<div className="row">
    <div className='loader margin-auto'></div>
  </div>);
}
